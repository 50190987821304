import React from "react";

function LoadingSpinner() {
  return (
    <div className="is-flex is-justify-content-center">
      <i className="fa fa-spinner fa-spin fa-2x"></i>
    </div>
  );
}

export default LoadingSpinner;
