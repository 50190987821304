"use strict";
exports.__esModule = true;
exports.routeTypeDefaultGradingSystem = exports.rockTypes = exports.routeTypes = exports.routeTags = exports.areaTags = exports.cragTags = exports.gradingSystems = void 0;
exports.gradingSystems = [
    {
        title: 'Australian',
        grades: [
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
        ]
    },
    {
        title: 'British',
        grades: [
            'M',
            'D',
            'VD',
            'VD 3c',
            'VD 4a',
            'VD 4b',
            'HVD',
            'HVD 3c',
            'HVD 4a',
            'HVD 4b',
            'HVD 4c',
            'HVD 5a',
            'S',
            'S 3c',
            'S 4a',
            'S 4b',
            'S 4c',
            'S 5a',
            'HS',
            'HS 4a',
            'HS 4b',
            'HS 4c',
            'HS 5a',
            'VS 4b',
            'VS 4c',
            'VS 5a',
            'VS 5b',
            'HVS 4c',
            'HVS 5a',
            'HVS 5b',
            'HVS 5c',
            'HVS 6a',
            'E1 5a',
            'E1 5b',
            'E1 5c',
            'E1 6a',
            'E1 6b',
            'E2 5b',
            'E2 5c',
            'E2 6a',
            'E2 6b',
            'E3 5c',
            'E3 6a',
            'E3 6b',
            'E3 6c',
            'E4 5c',
            'E4 6a',
            'E4 6b',
            'E4 6c',
            'E5 5c',
            'E5 6a',
            'E5 6b',
            'E5 6c',
            'E5 7a',
            'E6 6b',
            'E6 6c',
            'E6 7a',
            'E7 6b',
            'E7 6c',
            'E7 7a',
            'E8 6c',
            'E8 7a',
            'E9 7a',
            'E9 7b',
            'E10 7a',
            'E10 7b',
            'E11 7a',
            'E11 7b',
            'E12 7a',
            'E12 7b',
        ]
    },
    {
        title: 'Font',
        grades: [
            '3',
            '4',
            '5',
            '5+',
            '6A',
            '6A+',
            '6B',
            '6B+',
            '6C',
            '6C+',
            '7A',
            '7A+',
            '7B',
            '7B+',
            '7C',
            '7C+',
            '8A',
            '8A+',
            '8B',
            '8B+',
            '8C',
            '8C+',
            '9A',
            '9A+',
            '9B',
        ]
    },
    {
        title: 'French',
        grades: [
            '2',
            '2+',
            '3',
            '3+',
            '4',
            '4+',
            '5a',
            '5b',
            '5c',
            '6a',
            '6a+',
            '6b',
            '6b+',
            '6c',
            '6c+',
            '7a',
            '7a+',
            '7b',
            '7b+',
            '7c',
            '7c+',
            '8a',
            '8a+',
            '8b',
            '8b+',
            '8c',
            '8c+',
            '9a',
            '9a+',
            '9b',
            '9b+',
            '9c',
        ]
    },
    {
        title: 'Hueco',
        grades: [
            'VB',
            'V0',
            'V1',
            'V2',
            'V3',
            'V4',
            'V5',
            'V6',
            'V7',
            'V8',
            'V9',
            'V10',
            'V11',
            'V12',
            'V13',
            'V14',
            'V15',
            'V16',
            'V17',
        ]
    },
    {
        title: 'Kurtyka',
        grades: [
            'II',
            'II+',
            'III+',
            'IV',
            'IV+',
            'V-',
            'V',
            'V+',
            'VI',
            'VI+',
            'VI.1',
            'VI.1+',
            'VI.2',
            'VI.2+',
            'VI.3',
            'VI.3+',
            'VI.4',
            'VI.4+',
            'VI.5',
            'VI.5+',
            'VI.6',
            'VI.6+',
            'VI.7',
            'VI.7+',
            'VI.8',
        ]
    },
    {
        title: 'South African',
        grades: [
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            '16',
            '17',
            '18',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
        ]
    },
    {
        title: 'UIAA',
        grades: [
            'III-',
            'III',
            'III+',
            'IV-',
            'IV',
            'IV+',
            'V-',
            'V',
            'V+',
            'VI-',
            'VI',
            'VI+',
            'VII-',
            'VII',
            'VII+',
            'VIII-',
            'VIII',
            'VIII+',
            'IX-',
            'IX',
            'IX+',
            'X-',
            'X',
            'X+',
            'XI-',
            'XI',
            'XI+',
            'XII-',
            'XII',
            'XII+',
        ]
    },
    {
        title: 'Yosemite Decimal Scale',
        grades: [
            '5.1',
            '5.2',
            '5.3',
            '5.4',
            '5.5',
            '5.6',
            '5.7',
            '5.8',
            '5.9',
            '5.10a',
            '5.10b',
            '5.10c',
            '5.10d',
            '5.11a',
            '5.11b',
            '5.11c',
            '5.11d',
            '5.12a',
            '5.12b',
            '5.12c',
            '5.12d',
            '5.13a',
            '5.13b',
            '5.13c',
            '5.13d',
            '5.14a',
            '5.14b',
            '5.14c',
            '5.14d',
            '5.15a',
            '5.15b',
            '5.15c',
            '5.15d',
        ]
    },
];
exports.cragTags = [
    'Esoteric',
    'Family Friendly',
    'Good Landings',
    'Highballs',
    'Long Walk-in',
    'Lowballs',
    'Public Transport',
    'Quick Drying',
    'Quick Walk-in',
    'Slow Drying',
];
exports.areaTags = [
    'Bad Landings',
    'Easy Access',
    'Good Landings',
    'Hard to Find',
    'Highballs',
    'Lowballs',
    'Needs a Brush',
    'Quick Drying',
    'Slow Drying',
];
exports.routeTags = [
    'Arete',
    'Bad Landing',
    'Crack',
    'Crimps',
    'Dynamic',
    'Gaston',
    'Good Landing',
    'Highball',
    'Jugs',
    'Low Quality Rock',
    'Lowball',
    'Mantle',
    'Morpho',
    'Needs Brushing',
    'Overhanging',
    'Pinches',
    'Pockets',
    'Power',
    'Sidepulls',
    'Slab',
    'Slopers',
    'Traverse',
    'Undercuts',
    'Vert',
];
exports.routeTypes = ['Sport', 'Trad', 'Boulder'];
exports.rockTypes = [
    'Gritstone',
    'Limestone',
    'Plastic',
    'Sandstone',
    'Tuff',
    'Wood',
];
exports.routeTypeDefaultGradingSystem = {
    'Sport': 'French',
    'Trad': 'British',
    'Boulder': 'Font'
};
